<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.store || !subPermission.update"
    />
    <b-card :title="$t('g.theRequest')" v-else>
      <validation-observer ref="AIRForm">
        <b-form>
          <b-row>
            <!-- Proposed by -->
            <b-col md="4">
              <b-form-group :label="$t('g.proposedBy')">
                <validation-provider
                  #default="{ errors }"
                  name="proposed by"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.proposed_by"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.EnterTheProposedByHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Proposed by -->
            <!-- Date -->
            <b-col md="4">
              <b-form-group :label="$t('g.date')">
                <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.request_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Date -->
            <!-- Improvement Title -->
            <b-col md="4">
              <b-form-group :label="$t('g.improvementTitle')">
                <validation-provider
                  #default="{ errors }"
                  name="improvement title"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.improvement_title"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.EnterTheImprovementTitleHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Improvement Title -->
            <!-- Opinion on improvement request according to its type -->
            <b-col md="4">
              <b-form-group :label="$t('g.RequestAccordingToItsType')">
                <validation-provider
                  #default="{ errors }"
                  name="improvement request according to its type"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.its_type_option"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.EnterTheRequestAccordingToItsTypeHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--  ** Opinion on improvement request according to its type -->
            <!-- Opinion of Quality Management  -->
            <b-col md="4">
              <b-form-group :label="$t('g.OpinionOfQualityManagement')">
                <validation-provider
                  #default="{ errors }"
                  name="opinion of quality management"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.quality_management_opinion"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.EnterTheOpinionOfQualityManagementHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Opinion of Quality Management  -->
            <!-- Opinion of technical Department -->
            <b-col md="4">
              <b-form-group :label="$t('g.opinionOfTechnicalDepartment')">
                <validation-provider
                  #default="{ errors }"
                  name="opinion of technical department"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.tech_dep_opinion"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t(
                        'g.placeholder.EnterTheOpinionOfTechnicalDepartmentHere'
                      )
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Opinion of technical Department -->
            <!-- Reasons of improvement -->
            <b-col md="12">
              <b-form-group :label="$t('g.reasonsOfImprovement')">
                <validation-provider
                  #default="{ errors }"
                  name="reasons of improvement"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheReasonsOfImprovementHere')
                    "
                    v-model="form_data.reasons"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Reasons of improvement -->
            <!-- Improvement proposed -->
            <b-col md="12">
              <b-form-group :label="$t('g.improvementProposed')">
                <validation-provider
                  #default="{ errors }"
                  name="improvement proposed"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheImprovementProposedHere')
                    "
                    v-model="form_data.proposed"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="isEdit">
              <b-row>
                <b-col md="6" xl="6" class="mb-1">
                  <b-form-group
                    :label="$t('g.disposalReport')"
                    label-for="file"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="disposal report"
                      rules="required"
                    >
                      <b-form-file
                        :placeholder="$t('g.uploadYourFileHere')"
                        drop-placeholder="Drop file here..."
                        :state="errors.length > 0 ? false : null"
                        v-model="form_data.disposal_report"
                        accept=".doc,.docx,.pdf"
                      />
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" xl="6" class="mb-1">
                  <b-form-group
                    :label="$t('g.procedureSamplingReport')"
                    label-for="file"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="procedure sampling report"
                      rules="required"
                    >
                      <b-form-file
                        :placeholder="$t('g.uploadYourFileHere')"
                        drop-placeholder="Drop file here..."
                        v-model="form_data.procedure_sampling_report"
                        accept=".doc,.docx,.pdf"
                        :state="errors.length > 0 ? false : null"
                        multiple
                      />
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- ** Improvement proposed -->
            <b-col cols="12">
              <b-button
                @click.prevent="validationForm"
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import store from "@/store/index";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    BFormFile,
    Loading,
    Error,
    ContentNotView,
  },
  data() {
    return {
      form_data: {
        proposed_by: null,
        request_date: new Date(),
        improvement_title: null,
        its_type_option: null,
        quality_management_opinion: null,
        tech_dep_opinion: null,
        reasons: null,
        proposed: null,
        disposal_report: null,
        procedure_sampling_report: null,
      },
      isLoading: true,
      isEdit: false,
      isError: false,
      currentId: null,
      techId: null,
      improveId: null,
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddImprovementRequest") {
        this.handleWatchRoutePage();
      }
    },
  },

  created() {
    this.currentId = this.$route.params.id;
    this.techId = this.$route.params.techId;

    if (this.$route.name === "EditImprovementRequest") {
      this.isEdit = true;
      this.improveId = this.$route.params.improveId;
      this.getImprovementRequests(
        this.$route.params.id,
        this.$route.params.techId,
        this.$route.params.improveId
      );
    }
  },

  beforeMount() {
    setTimeout(() => {
      this.isLoading = false;
      this.isError = false;
      store.dispatch("GET_PERMISSION", "improveRequests");
      this.subPermission = store.state.permissions.sub;
    }, 2000);
  },

  methods: {
    getImprovementRequests(id, techId, improveId) {
      this.$http
        .get(
          `admin/serviceRequests/${id}/techEvaluations/${techId}/improveRequests/${improveId}`
        )
        .then((res) => {
          if (res.status === 200) {
            const data = response.data.data;

            console.log(data);

            this.form_data.proposed_by = data?.proposed_by;
            this.form_data.improvement_title = data?.improvement_title;
            this.form_data.its_type_option = data?.its_type_option;
            this.form_data.quality_management_opinion =
              data?.quality_management_opinion;
            this.form_data.tech_dep_opinion = data?.tech_dep_opinion;
            this.form_data.reasons = data?.reasons;
            this.form_data.proposed = data?.proposed;
            this.form_data.request_date = data?.request_date;
            this.form_data.disposal_report = data?.disposal_report;
            this.form_data.procedure_sampling_report =
              data?.procedure_sampling_report;

            this.error = false;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.error = true;
          this.loading = false;
        });
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    handleWatchRoutePage() {
      this.isEdit = false;
      this.clearForm();
      this.$refs.form.reset();
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
        if (i === "date") {
          form[i] = new Date();
        }
      }
    },
    validationForm() {
      if (this.isEdit) {
        this.submitEditData();
      } else {
        this.submitData();
      }
    },
    submitData() {
      let formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }

      this.$refs.AIRForm.validate().then((success) => {
        if (success) {
          this.$http
            .post(
              `admin/serviceRequests/${this.currentId}/techEvaluations/${this.techId}/improveRequests`,
              formData
            )
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              this.clearForm();
              this.$refs.AIRForm.reset();
              setTimeout(() => {
                this.$router.push({
                  name: "ViewTechEvaluation",
                  params: { id: this.currentId, tech_id: this.techId },
                });
              }, 1000);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    submitEditData() {
      let formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }

      this.$refs.AIRForm.validate().then((success) => {
        if (success) {
          this.$http
            .post(
              `admin/serviceRequests/${this.currentId}/techEvaluations/${this.techId}/improveRequests/${this.improveId}?_method=put`,
              formData
            )
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              this.clearForm();
              this.$refs.AIRForm.reset();
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>

<style></style>
