var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(!_vm.subPermission.store || !_vm.subPermission.update)?_c('content-not-view'):_c('b-card',{attrs:{"title":_vm.$t('g.theRequest')}},[_c('validation-observer',{ref:"AIRForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.proposedBy')}},[_c('validation-provider',{attrs:{"name":"proposed by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.EnterTheProposedByHere')},model:{value:(_vm.form_data.proposed_by),callback:function ($$v) {_vm.$set(_vm.form_data, "proposed_by", $$v)},expression:"form_data.proposed_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.date')}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.request_date),callback:function ($$v) {_vm.$set(_vm.form_data, "request_date", $$v)},expression:"form_data.request_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.improvementTitle')}},[_c('validation-provider',{attrs:{"name":"improvement title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.EnterTheImprovementTitleHere')},model:{value:(_vm.form_data.improvement_title),callback:function ($$v) {_vm.$set(_vm.form_data, "improvement_title", $$v)},expression:"form_data.improvement_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.RequestAccordingToItsType')}},[_c('validation-provider',{attrs:{"name":"improvement request according to its type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.EnterTheRequestAccordingToItsTypeHere')},model:{value:(_vm.form_data.its_type_option),callback:function ($$v) {_vm.$set(_vm.form_data, "its_type_option", $$v)},expression:"form_data.its_type_option"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.OpinionOfQualityManagement')}},[_c('validation-provider',{attrs:{"name":"opinion of quality management","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.EnterTheOpinionOfQualityManagementHere')},model:{value:(_vm.form_data.quality_management_opinion),callback:function ($$v) {_vm.$set(_vm.form_data, "quality_management_opinion", $$v)},expression:"form_data.quality_management_opinion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.opinionOfTechnicalDepartment')}},[_c('validation-provider',{attrs:{"name":"opinion of technical department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t(
                      'g.placeholder.EnterTheOpinionOfTechnicalDepartmentHere'
                    )},model:{value:(_vm.form_data.tech_dep_opinion),callback:function ($$v) {_vm.$set(_vm.form_data, "tech_dep_opinion", $$v)},expression:"form_data.tech_dep_opinion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.reasonsOfImprovement')}},[_c('validation-provider',{attrs:{"name":"reasons of improvement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheReasonsOfImprovementHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.reasons),callback:function ($$v) {_vm.$set(_vm.form_data, "reasons", $$v)},expression:"form_data.reasons"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.improvementProposed')}},[_c('validation-provider',{attrs:{"name":"improvement proposed","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheImprovementProposedHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.proposed),callback:function ($$v) {_vm.$set(_vm.form_data, "proposed", $$v)},expression:"form_data.proposed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),(_vm.isEdit)?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.disposalReport'),"label-for":"file"}},[_c('validation-provider',{attrs:{"name":"disposal report","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null,"accept":".doc,.docx,.pdf"},model:{value:(_vm.form_data.disposal_report),callback:function ($$v) {_vm.$set(_vm.form_data, "disposal_report", $$v)},expression:"form_data.disposal_report"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,1690806987)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.procedureSamplingReport'),"label-for":"file"}},[_c('validation-provider',{attrs:{"name":"procedure sampling report","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","accept":".doc,.docx,.pdf","state":errors.length > 0 ? false : null,"multiple":""},model:{value:(_vm.form_data.procedure_sampling_report),callback:function ($$v) {_vm.$set(_vm.form_data, "procedure_sampling_report", $$v)},expression:"form_data.procedure_sampling_report"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,3801289315)})],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":_vm.isEdit ? 'success' : 'primary',"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }